import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Contact from 'src/component/contact';
import Flutter from 'src/component/flutter';
import Home from 'src/component/home';
import News from 'src/component/news';
import Service from 'src/component/service';
import 'src/index.css';
import { MainLayout } from 'src/layout';
import reportWebVitals from 'src/reportWebVitals';
import * as serviceWorkerRegistration from 'src/serviceWorkerRegistration';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <MainLayout page="home" description="reactApp">
              <Home />
            </MainLayout>
          }
        />
        <Route
          path="/service"
          element={
            <MainLayout page="service" description="reactApp">
              <Service />
            </MainLayout>
          }
        />
        <Route
          path="/news"
          element={
            <MainLayout page="news" description="reactApp">
              <News />
            </MainLayout>
          }
        />
        <Route
          path="/contact"
          element={
            <MainLayout page="contact" description="reactApp">
              <Contact />
            </MainLayout>
          }
        />
        <Route
          path="/web/app.html"
          element={
            <MainLayout page="flutter" description="flutterApp">
              <Flutter />
            </MainLayout>
          }
        />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

serviceWorkerRegistration.register();

reportWebVitals();
