import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Footer } from 'src/component/footer';
import Header from 'src/component/header';
import { LayoutProps } from './types';
const Items = {
  home: {
    ogTitle: { content: 'home' },
    ogDiscription: { content: 'ホーム' },
    ogType: { content: 'website' },
    ogUrl: { content: 'https://rerate.app' },
    ogImage: { content: '/ogp-image/ogp-test.png' },
    ogSiteName: { content: 'Rerate' },
  },
  contact: {
    ogTitle: { content: 'contact' },
    ogDiscription: { content: 'コンタクト' },
    ogType: { content: 'article' },
    ogUrl: { content: 'https://rerate.app/contact' },
    ogImage: { content: '/ogp-image/ogp-test.png' },
    ogSiteName: { content: 'Rerate' },
  },
};
export function MainLayout({ page, children, description }: LayoutProps): JSX.Element {
  let a = Items.home;
  switch (window.location.pathname) {
    case '/':
      a = Items.home;
      break;
    case '/contact':
      a = Items.contact;
      break;
  }

  return (
    <>
      <HelmetProvider>
        <Helmet title={`Rerate ${page}`} meta={[{ property: 'og:description', content: 'uuu' }]} />
      </HelmetProvider>
      {window.location.pathname === '/contact' ? null : <Header />}
      <main>{children}</main>
      {window.location.pathname === '/contact' ? null : <Footer />}
    </>
  );
}
